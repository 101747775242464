
ul a {
    text-decoration: none !important;
    color: rgb(255, 255, 255) !important;
}

ul a span {
    font-size: 2rem;
    font-weight: 200;
    line-height: 4rem;
}

ul a span:hover {
    color: var(--yellowOrange) !important;
    transition: .2s ease-in-out .1s;
    font-weight: 300;
}

.logo img {
    width: 28px;
}

.logoLogin img{
    width: 45px;
}

@media only screen and (max-width: 1000px) {
    .logoLogin img{
        width: 40px;
    }
}

@media only screen and (max-width: 700px) {
    .logoLogin img{
        width: 35px;
    }
}

@media only screen and (max-width: 450px) {
    .logoLogin img{
        width: 32px;
    }
}

.h6 {
    font-size: 16px;
    font-weight: 300;
}

/* sidebar */
.bottomBorder {
    border-bottom: 3px solid #222;
}

.bottomBorderActive {
    border-bottom: 3px solid #fff;
}

/* inside navbar */
.insideNavbar > div {
 background-color: #222;
 padding: 10px 20px;
 border-radius: 6px;
}