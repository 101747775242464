:root {
    --bluePrimary: #0a74c0;
    --fucsiaRed: #e52c5d;
    --chatColor: #90d800;
    --chatNotifyColor: #eaffc0;
    --yellowOrange: #f0bd35;
    --tiffany: #0dcaf0;
    --deepViolet: #9700fc;
    --brightFucsia: #ff00d9;
    --lightGray: #d2d2d2;
    --softGray: #a8a8a8;
    --midGray: #8794a0;
    --dark666: #666;
    --dark555: #555;
    --dark444: #444;
    --dark333: #333;
    --dark222: #222;
    --dark111: #111;
    --absoluteBlack: black;
    --paypalBlue: #1e477a;
    --stripePurble: #635bff;
    --weeklyDarkAqua: #3a7469;
    --weeklyLightAqua: #66cfbc;
    --monthlyDarkFucsia: #892060;
    --monthlyLightFucsia: #e337a1;

    --bs-primary-rgb: 9, 90, 218;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 !important;
}

.noTextBreak h3,
.noTextBreak h4,
.noTextBreak p {
    word-wrap: normal;
    word-break: normal;
}

.noBreak {
    word-wrap: normal;
    word-break: normal;
}

.myCursor {
    cursor: pointer;
}

.myCursorHover {
    cursor: pointer;
}

.myCursorHover:hover {
    text-decoration: underline;
}

.noStyle {
    list-style: none;
}

.noLinkStyle {
    text-decoration: none !important;
    color: #fff !important;
}

.noDecoration {
    text-decoration: none !important;
}

.preWrap {
    white-space: pre-wrap;
}

/* z-index */
.myZindex2 {
    z-index: 2;
}

.myZindex3 {
    z-index: 3;
}

.myZindex4 {
    z-index: 4;
}

.myZindex5 {
    z-index: 5;
}

.myZindex6 {
    z-index: 6;
}

.myZindex99 {
    z-index: 99;
}

.myZindexSideBar {
    z-index: 102;
}

.myZindexMax {
    z-index: 999;
}

/* height */
.myVh100 {
    height: 100vh !important;
}

.myMinVh100 {
    min-height: calc(100vh - 65px);
}

.myMaxVh100 {
    max-height: calc(100vh - 65px);
}

.myVhFull {
    height: calc(100vh - 65px);
}

.myMaxH300 {
    max-height: 300px !important;
}

.myMaxH500 {
    max-height: 500px !important;
}

.myH400 {
    height: 400px;
}


/* height & width */
.mySquare80 {
    width: 80px;
    height: 80px;
}

.mySquare100 {
    width: 100px;
    height: 100px;
}


.myWidth33Percent {
    width: 33.33%;
}

.myWidth60Percent {
    width: 60%;
}


/* min width */
.myMinW375 {
    min-width: 375px;
}

/* max width */
.myMaxW80 {
    max-width: 80px !important;
}

.myMaxW100 {
    max-width: 100px !important;
}

.myMaxW150 {
    max-width: 150px !important;
}

.myMaxW200 {
    max-width: 200px !important;
}

.myMaxW300 {
    max-width: 300px !important;
}

.myMaxW400 {
    max-width: 400px !important;
}

.myMaxW500 {
    max-width: 500px !important;
}

.myMaxW600 {
    max-width: 600px !important;
}

.myMaxW632 {
    max-width: 632px !important;
}

.myMaxW700 {
    max-width: 700px !important;
}

.myMaxW800 {
    max-width: 800px !important;
}

.myMaxW1000 {
    max-width: 1000px !important;
}

.myMaxW1200 {
    max-width: 1200px !important;
}

.myMaxW1400 {
    max-width: 1400px !important;
}

/* color */
.myBlueColor {
    color: var(--bluePrimary);
}

.myLightGrayColor {
    color: var(--lightGray)
}

.mySoftGrayColor {
    color: var(--softGray)
}

.myMidGrayColor {
    color: var(--midGray);
}

.myFucsiaRed {
    color: var(--fucsiaRed);
}

.myChatColor {
    color: var(--chatColor);
}

.myYellowColor {
    color: var(--yellowOrange);
}

.myAquaColor {
    color: var(--tiffany);
}

.myDeepVioletColor {
    color: var(--deepViolet);
}

.myStripeColor {
    color: var(--stripePurble);
}

.myPaypalColor {
    color: var(--paypalBlue);
}

.myDarkGrayColor {
    color: var(--dark666);
}


/* background color */

.myBgWhite {
    background-color: #fff;
}

.myBgAqua {
    background-color: var(--tiffany);
}

.myBgRed {
    background-color: var(--fucsiaRed);
}

.myBgDarkGray {
    background-color: var(--dark666);
}

.myBgDark {
    background-color: var(--dark333);
}

.myBgDark555 {
    background-color: var(--dark555);
}

.myBgDark444 {
    background-color: var(--dark444);
}

.myBgDark222 {
    background-color: var(--dark222);
}

.myBgDark111 {
    background-color: var(--dark111);
}

.myBgAbsBlack {
    background-color: var(--absoluteBlack);
}

.myBgLightGray {
    background-color: var(--lightGray);
}

.myBgSoftGray {
    background-color: var(--softGray);
}

.myBgMidGray {
    background-color: var(--midGray);
}

.myBgYellow {
    background-color: var(--yellowOrange);
}

.myBgPrimary {
    background-color: var(--bluePrimary);
}

.myBgFucsiaRed {
    background-color: var(--fucsiaRed);
}

.myBgDeepViolet {
    background-color: var(--deepViolet);
}

.myBgChat {
    background-color: var(--chatColor);
}

.myBgChatSelected {
    background-color: var(--dark333);
}

.myBgChatSelected>.notifySelected {
    background-color: var(--dark333) !important;
    color: #ebebeb !important;
}

.myBgTransparentMedium {
    background: #cfcfcf78;
}

.myBgTransparentSpecial {
    background: #00000097;
}

.myBgTransparentHigh {
    background: #cfcfcfb8;
}

.myBgTransparentVeryHigh {
    background: #0f0f0fee;
}

.myBgTransparentVerticalGradient {
    background: linear-gradient(to bottom, var(--dark222), rgba(0, 0, 0, 0));
}

.overlayAnnouncementBackArrow {
    background: linear-gradient(to bottom, #00000060 1%, #0000001d, rgba(255, 255, 255, 0) 40%);
}

.myBgLikeNotify {
    background: linear-gradient(to right, #ffffff00, #e52c5db8);
}

.myBgChatNotify {
    background: linear-gradient(to right, #ffffff00, #90d80099);
}

.myBgLikeAndChatNotify {
    background: linear-gradient(to right, #90d80099, #e52c5db8);
}

.myBgLikeAndChatNotify2 {
    background: linear-gradient(to right, #ffffff00, #90d800b7 68%, #e52c5db8);
}

.myBgLikeNotify2 {
    background: linear-gradient(to right, #ffffff00, #e52c5db8);
}


/* background image */
.myBgImgContain {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.myBgImgCover {
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
}

.myBgImgCover2 {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.myImgActiveRing {
    border: 3px solid var(--chatColor) !important;
}

.myImgRejectRing {
    border: 3px solid var(--fucsiaRed) !important;
}

.myImgPendingRing {
    border: 3px solid var(--lightGray) !important;
}

.myImgArchivedRing {
    border: 3px solid var(--yellowOrange) !important;
}

/* icons font size */
.myIconLg {
    font-size: 50px;
}

/* font size */
.myFontSize5 {
    font-size: .5rem;
}

.myFontSize6 {
    font-size: .6rem;
}

.myFontSizeNotifyCount {
    font-size: 10px !important;
}

.myFontSize12px {
    font-size: 12px;
}

.myFontSize14px {
    font-size: 14px;
}

.myFontSize16px {
    font-size: 16px;
}

.myFontSize20px {
    font-size: 20px;
}

.myFontSize50px {
    font-size: 50px !important;
}

.myFontSize3Em {
    font-size: 3em;
}

.myFontSizeTutorial {
    font-size: 6rem;
}

.myFontSize07Em {
    font-size: .7em;
}

/* text truncate and fading */
.line-clampUserName {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: italic;
    font-weight: 200;
}

.line-clampBeta {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media only screen and (max-width: 500px) {
    .line-clampAnn {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (min-width: 700px) {
    .line-clampBeta {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.clampDarkFading {
    background: linear-gradient(to right, #ffffff00 80%, var(--dark333));
}

/* myGradients */
.myDarkGradient {
    background: linear-gradient(to bottom, #333, var(--dark222));
    color: #fff;
}

.myGrayGradient {
    background: linear-gradient(to bottom, #6e6e6e, #afafaf);
    color: #111;
}

.myPremiumGradient {
    background: linear-gradient(to bottom, var(--dark222), var(--yellowOrange));
    color: #fff;
}

.myGradientFreely {
    /* background: linear-gradient(to top, #d08a6e 40%, #f2a080); */
    background: linear-gradient(to top, #646464 40%, #a9a9a9);
}

.myGradientWeekly {
    background: linear-gradient(to top, var(--weeklyDarkAqua) 20%, var(--weeklyLightAqua))
}

.myGradientMonthly {
    background: linear-gradient(to top, var(--monthlyDarkFucsia) 20%, var(--monthlyLightFucsia));
}

.scoreBarLow {
    background: linear-gradient(to right, rgb(255, 60, 0) 10%, rgb(255, 225, 0));
}

.scoreBarMedium {
    background: linear-gradient(to right, rgb(255, 238, 0) 10%, rgb(140, 255, 0));
}

.scoreBarHigh {
    background: linear-gradient(to right, rgb(0, 255, 213) 10%, rgb(0, 147, 252));
}

.scoreBarLegend {
    /* background: linear-gradient(to right, rgb(255, 0, 217) 10%, rgb(181, 0, 252)); */
    background: linear-gradient(to right, var(--brightFucsia) 10%, var(--deepViolet));
}

/* overflow y */
.myOverflowY {
    overflow-y: scroll;
}

.myOverflowYHidden {
    overflow-y: hidden;
}

.myOverFlowXHidden {
    overflow-x: hidden;
}

.myOverFlowXScroll {
    overflow-x: scroll;
}

/* blur */
.myBlurryPrice {
    filter: blur(6px);
}

/* input border danger */
.form-control.highlightError {
    border: 3px solid red !important;
}

.borderSuperuser {
    border: 3px solid var(--deepViolet);
}

.paddingTopTutorial {
    padding-top: 4rem;
}

/* cradChatUser */
.cardChatUser {
    border-top-left-radius: 31px;
    border-bottom-left-radius: 31px;
}

.cardChatUserImg {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    margin: 2px;
}

/* images dots counter */
.imgDotsCounterActive {
    height: 8px;
    width: 8px;
}

.imgDotsCounter {
    height: 6px;
    width: 6px;
}