.outletButton{
    background-color: var(--yellowOrange);
    border: none;
   }

.outletButton:hover{
    background-color: var(--tiffany);
    transition: .2s ease-in-out .1s;
}



