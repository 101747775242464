.percentageBar {
    animation: progress;
    animation-duration: 2000ms;
    animation-timing-function: ease-in-out;
}


.percentageBar2 {
    animation: progress;
    animation-duration: 30s;
    animation-timing-function: linear;
}

.percentageBar3 {
    animation: progress;
    animation-duration: 10s;
    animation-timing-function: linear;
}


.percentageBar4 {
    animation: progress;
    animation-duration: 1000ms;
    animation-timing-function: linear;
}

.percentageBar5 {
    animation: progress;
    animation-duration: 3s;
    animation-timing-function: linear;
}

.percentageBarGlow {
    animation: glow;
    animation-duration: 2.3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background: linear-gradient(to right, #ffffff00, #bec7ff, #ffffff00);
}

.percentageBarChatGlow {
    animation: glow2;
    animation-duration: 1.4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    background: linear-gradient(to right, #ffffff00 40%, var(--yellowOrange), #ffffff00 60%);
    background-size: 250% 100%;
}

.jumpingAnimation {
    animation: jumping;
    animation-duration: 1.7s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.pulsingAnimation {
    animation: pulsing;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.tapAnimation {
    animation: tapping;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: 3;
}

.popImgAnim {
    opacity: 0;
    transform: scale(0);
    animation: popImg;
    animation-duration: .2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.gridAnimation {
    animation: popImg;
    opacity: 0;
    transform: scale(0);
    animation-fill-mode: forwards;
    animation-delay: .1s;
    animation-duration: .2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
}

.popImgAnim:nth-child(1) {
    animation-delay: 0s
}

.popImgAnim:nth-child(2) {
    animation-delay: .1s
}

.popImgAnim:nth-child(3) {
    animation-delay: .2s
}

.popImgAnim:nth-child(4) {
    animation-delay: .3s
}

.popImgAnim:nth-child(5) {
    animation-delay: .4s
}

.popImgAnim:nth-child(6) {
    animation-delay: .5s
}

.popImgAnim:nth-child(7) {
    animation-delay: .6s
}

.popImgAnim:nth-child(8) {
    animation-delay: .7s
}

.popImgAnim:nth-child(9) {
    animation-delay: .8s
}

.popImgAnim:nth-child(10) {
    animation-delay: .9s
}

.popImgAnim:nth-child(11) {
    animation-delay: 1s
}

.popImgAnim:nth-child(12) {
    animation-delay: 1.1s
}




/* -------- */
@keyframes progress {
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
}

@keyframes glow {
    0% {
        left: 0%;
        opacity: 0%;
    }

    50% {
        opacity: 30%;
    }

    100% {
        left: 70%;
        opacity: 0%;
    }
}

@keyframes glow2 {
    0% {
        background-position: 100%;
    }

    100% {
        background-position: 0%;
    }
}

@keyframes jumping {
    0% {
        transform: translateY(0);
    }

    10% {
        transform: translateY(-40px);
    }

    20% {
        transform: translateY(7px);
    }

    30% {
        transform: translateY(-30px);
    }

    40% {
        transform: translateY(5px);
    }

    50% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(3px);
    }

    70% {
        transform: translateY(-10px);
    }

    80% {
        transform: translateY(1px);
    }

    90% {
        transform: translateY(-5px);
    }

    100% {
        transform: translateY(0);
    }
}


@keyframes pulsing {
    0% {
        transform: scale(1);
    }

    15% {
        transform: scale(1.4);
    }

    25% {
        transform: scale(1.2);
    }

    32% {
        transform: scale(1.5);
    }

    60% {
        transform: scale(1);
    }

    70% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes tapping {
    0% {
        transform: scale(1) rotateX(0deg);
    }

    60% {
        transform: scale(.94) rotateX(18deg);
    }

    75% {
        transform: scale(.87) rotateX(32deg);
    }

    100% {
        transform: scale(1) rotateX(0deg);
    }
}

@keyframes popImg {

    100% {
        opacity: 100%;
        transform: scale(1)
    }
}